import React, { PropsWithChildren, FC, useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { useTheme, Theme, CSSObject } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import SvgIcon from '@mui/material/SvgIcon'

import ShareLocationIcon from '@mui/icons-material/ShareLocation'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined'
import DirectionsBusOutlinedIcon from '@mui/icons-material/DirectionsBusOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import Button from '@mui/material/Button/Button'
import Backdrop from '../components/UI/Backdrop'
import Typography from '@mui/material/Typography'

import { COLORS } from '../theme'
import Grid from '@mui/material/Grid'

import { useAuth0 } from '@auth0/auth0-react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { useLocalStorage } from 'usehooks-ts'
import { useUserMe } from '../queries/user'

export const drawerWidth = 236
export const closedDrawerWidth = 74
export const toolbarHeight = 88

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: closedDrawerWidth
})

const NAVIGATION = [
    {
        to: '/tracking',
        icon: <ShareLocationIcon />,
        label: 'Tracking'
    },
    {
        to: '/routes?page=1',
        icon: <RouteOutlinedIcon />,
        label: 'Routes'
    },
    {
        to: '/vehicles?page=1',
        icon: <DirectionsBusOutlinedIcon />,
        label: 'Vehicles'
    },
    {
        to: '/operators?page=1',
        icon: <ManageAccountsOutlinedIcon />,
        label: 'Operators'
    },
    {
        to: '/users?page=1',
        icon: <ContactPageOutlinedIcon />,
        label: 'Users'
    }
]

const General: FC<PropsWithChildren> = ({ children }) => {
    const theme = useTheme()
    const { pathname } = useLocation()
    const { logout, user } = useAuth0()
    const { data: userMe } = useUserMe()
    const largeLogo = `https://afastrax.blob.core.windows.net/bannerlogos/${userMe?.customer.replaceAll(" ", "").toLowerCase()}_large.png`
    const smallLogo = `https://afastrax.blob.core.windows.net/bannerlogos/${userMe?.customer.replaceAll(" ", "").toLowerCase()}_small.png`

    const [open, setOpen] = useLocalStorage('OPEN_LEFT_BAR', false)
    const [isOpenLogoutDialog, setIsOpenLogoutDialog] = useState(false)

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', position: 'relative' }}>
            <CssBaseline />
            <AppBar
                position='fixed'
                sx={{
                    width: `calc(100% - ${open ? drawerWidth : closedDrawerWidth}px)`,
                    ml: `${open ? drawerWidth : closedDrawerWidth}px`,
                    height: `${toolbarHeight}px`,
                    background: COLORS.WHITE,
                    boxShadow: 'none'
                }}
            >
                <Toolbar
                    sx={{
                        height: '100%',
                        borderBottom: `solid 1px ${COLORS.BORDER_GRAY}`,
                        padding: '0',
                        pl: '0!important',
                        pr: '0!important',
                        marginX: '24px',
                        justifyContent: 'end'
                    }}
                >
                    {!!user && !!user.name && (
                        <Grid container width='auto' alignItems='center'>
                            <Avatar alt={user.name} src={user.picture} />
                            <Typography ml='8px' variant='text' sx={{ color: COLORS.MAIN_DARK }}>
                                {user.name}
                            </Typography>
                        </Grid>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    flexShrink: 0,
                    ...(open && {
                        ...openedMixin(theme)
                    }),
                    ...(!open && {
                        ...closedMixin(theme)
                    }),
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        background: userMe?.customer === "Go Ahead London" ?
                            COLORS.MAIN_BLUE : "#667399",

                        padding: '10px',
                        justifyContent: 'space-between',
                        ...(open ? openedMixin(theme) : closedMixin(theme))
                    }
                }}
                variant='permanent'
                anchor='left'
                open={open}
            >
                <Grid container flexDirection='column' justifyContent='end'>
                    <Box sx={open ? { padding: '14px' } : { pt: '30px' }}>
                        {open ? (
                            <img width="188" height="66" src={largeLogo} />
                        ) : (
                            <img width="37" height="37" style={{ marginLeft: "10px" }} src={smallLogo} />
                        )}
                    </Box>
                    <List>
                        {NAVIGATION.map(({ to, icon, label }) => (
                            <NavLink
                                key={to}
                                to={to}
                                style={{ textDecoration: 'unset' }}
                                onClick={() => (document.title = `Bus routing - ${label}`)}
                            >
                                <ListItem
                                    sx={{
                                        width: open ? '216px' : '54px',
                                        height: '54px',
                                        borderRadius: '8px',
                                        background: to.includes(pathname.split('/')[1]) ?
                                            userMe?.customer === "Go Ahead London" ?
                                                'hwb(208deg 24% 24%)' : 'hwb(208deg 40% 40%)'
                                            : ''
                                    }}
                                    disablePadding
                                >
                                    <ListItemButton
                                        sx={{ padding: '16px 14px', height: '54px', borderRadius: '8px' }}
                                    >
                                        <ListItemIcon sx={{
                                            color: COLORS.WHITE
                                        }}>{icon}</ListItemIcon>


                                        <ListItemText primary={label} sx={{
                                            color: COLORS.WHITE
                                        }} />
                                    </ListItemButton>
                                </ListItem>
                            </NavLink>
                        ))}
                    </List>
                </Grid>

                <Grid container>
                    <ListItem
                        sx={{
                            height: '54px',
                            borderRadius: '8px',
                            background: userMe?.customer === "Go Ahead London" ? 'hwb(208deg 24% 24%)' : 'hwb(208deg 40% 40%)',
                            mb: '34px'
                        }}
                        disablePadding
                        onClick={() => setIsOpenLogoutDialog(true)}
                    >
                        <ListItemButton
                            sx={{ padding: '16px 14px', height: '54px', borderRadius: '8px', justifySelf: 'end' }}
                        >
                            <ListItemIcon sx={{
                                color: COLORS.WHITE
                            }}>
                                <LogoutOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Sign Out'} sx={{
                                color: COLORS.WHITE
                            }} />
                        </ListItemButton>
                    </ListItem>

                    <IconButton
                        sx={{
                            border: `1px solid ${COLORS.WHITE}`,
                            background: userMe?.customer === "Go Ahead London"
                                ? COLORS.MAIN_BLUE : '#667399',
                            width: '28px',
                            height: '28px',
                            alignSelf: 'center',
                            margin: '0 auto'
                        }}
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardDoubleArrowLeftIcon htmlColor={COLORS.WHITE} />
                        ) : (
                            <KeyboardDoubleArrowRightIcon htmlColor={COLORS.WHITE} />
                        )}
                    </IconButton>
                </Grid>
            </Drawer>

            <Box
                component='main'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'stretch',
                    p: '16px 12px 25px 12px',
                    marginTop: `${toolbarHeight}px`,
                    width: '100%',
                    minHeight: `calc(100vh - ${toolbarHeight}px)`
                }}
            >
                {children}
            </Box>

            {/* a logout dialog */}
            <Dialog
                open={isOpenLogoutDialog}
                onClose={() => setIsOpenLogoutDialog(false)}
                sx={{ '& .MuiPaper-root': { minWidth: '670px', height: '305px' } }}
            >
                <IconButton
                    onClick={() => setIsOpenLogoutDialog(false)}
                    sx={{ position: 'absolute', right: '10px', top: '10px' }}
                >
                    <CloseIcon fontSize='small' />
                </IconButton>
                <Grid
                    container
                    justifyContent='space-around'
                    direction='column'
                    alignItems='center'
                    height='100%'
                >
                    <DialogTitle textAlign='center' mt='32px' mb='8px' width='400px' variant='title1'>
                        <Typography variant='title1Bold'>Are you sure you want to sign out?</Typography>
                    </DialogTitle>
                    <Box sx={{ padding: '0 24px 48px 24px', textAlign: 'center' }}>
                        <DialogActions sx={{ padding: 'unset' }} disableSpacing>
                            <Button
                                sx={{ width: '50%' }}
                                variant='outlined'
                                onClick={() => setIsOpenLogoutDialog(false)}
                            >
                                Cancel
                            </Button>
                            <Box width='16px'></Box>
                            <Button
                                sx={{
                                    width: '50%',
                                    background: userMe?.customer === "Go Ahead London" ?
                                        COLORS.MAIN_BLUE : '#667399'
                                }}
                                variant='contained'
                                onClick={() => logout({ returnTo: window.location.origin })}
                                autoFocus
                            >
                                Sign out
                            </Button>
                        </DialogActions>
                    </Box>
                </Grid>
            </Dialog>
            {/* a logout dialog */}
        </Box>
    )
}

export default General
